var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_vm._m(0),_c('div',{staticClass:"bg-light-grey"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 pt-5 pb-5"},[_c('hr',{staticClass:"wide blue-line short"}),_vm._m(1),_c('hr',{staticClass:"wide blue-line short"}),_vm._m(2),_c('hr',{staticClass:"wide blue-line short"}),_vm._m(3),_c('hr',{staticClass:"wide blue-line short"}),_vm._m(4),_c('hr',{staticClass:"wide blue-line short"}),_vm._m(5),_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell small-12 medium-1 medium-offset-11"},[_c('router-link',{attrs:{"to":"/how-to-use"}},[_c('button',{staticClass:"button expanded"},[_vm._v("Next")])])],1)])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-mid-grey mt-5"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-x grid-padding-x"},[_c('div',{staticClass:"cell small-12 pt-5 pb-5"},[_c('h1',{staticClass:"page-header"},[_vm._v("Model overview")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x pb-2"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Purpose")])]),_c('div',{staticClass:"cell small-12 medium-10"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Forecast the potential budget impact of Byooviz for the treatment of: ")]),_c('ul',[_c('li',[_vm._v("Neovascular (wet) age-related macular degeneration (wAMD)")]),_c('li',[_vm._v("Visual impairment due to diabetic macular oedema (DME)")]),_c('li',[_vm._v("Proliferative diabetic retinopathy (PDR)")]),_c('li',[_vm._v(" Visual impairment due to macular oedema secondary to retinal vein occlusion (branch RVO or central RVO) ")]),_c('li',[_vm._v("Visual impairment due to choroidal neovascularisation (CNV)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x pb-2"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Population")])]),_c('div',{staticClass:"cell small-12 medium-10"},[_c('p',{staticClass:"mb-0"},[_vm._v(" The number of patients with any of the indications listed above who are eligible for treatment. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x pb-2"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Time Horizon")])]),_c('div',{staticClass:"cell small-12 medium-10"},[_c('p',{staticClass:"mb-0"},[_vm._v(" 1 to 3 years ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x pb-2"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Cost")])]),_c('div',{staticClass:"cell small-12 medium-10"},[_c('p',{staticClass:"mb-0"},[_vm._v(" The model is a drug acquisition model only. Monitoring costs and costs associated with inpatient or outpatient follow-up are not included. The costs associated with any other drugs used to treat patients with these indications are not included. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-x pb-2"},[_c('div',{staticClass:"cell small-12 medium-2"},[_c('h4',[_vm._v("Key"),_c('br'),_vm._v("Assumptions")])]),_c('div',{staticClass:"cell small-12 medium-10"},[_c('ul',[_c('li',[_vm._v(" New patients started on treatment are assumed to be on therapy for 6 months in the first year. This is to allow for the reality that some patients will start in month 1 and some in month 12. ")]),_c('li',[_vm._v(" All patients started or switched are assumed to receive treatment in subsequent years. ")]),_c('li',[_vm._v(" Patient drop out is not included. Patients started on treatment or switched to a different ranibizumab brand are assumed to stay on treatment for the duration of the modelling period. ")]),_c('li',[_vm._v(" Dosing assumptions are based on the product's SmPC, although dosing frequency may vary on a patient by patient basis based on clinical response to treatment. ")]),_c('li',[_vm._v(" Default prices included in the model are based on NHS list price. The model makes provision to include discounts or local prices. ")])])])])
}]

export { render, staticRenderFns }